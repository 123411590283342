globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"6e5927d528aa7ddaf6c1b52ca6db8676d0412829"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

Sentry.init({
  dsn: "https://afd71eebd38cd226653ab3277d1804dc@o4508614510641152.ingest.us.sentry.io/4508614661636096",
  tracesSampleRate: 1,
  debug: false,
  environment: process.env.NEXT_PUBLIC_VERCEL_ENV,
  release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
  beforeSend: (event) => {
    if (process.env.NEXT_PUBLIC_VERCEL_ENV === "development") {
      console.warn(`
        sentry [debug]: Event captured! (Won't be sent in the development environment)
        -----------------------------------
        Exception: ${event?.exception ? JSON.stringify(event.exception, null, 2) : ""}
        User: ${event?.user ? JSON.stringify(event.user, null, 2) : "No user"}
        Contexts: ${event?.contexts ? JSON.stringify(event.contexts, null, 2) : "No context"}
        Extra: ${event?.extra ? JSON.stringify(event.extra, null, 2) : "No extra data"}
        -----------------------------------
      `);

      return null;
    }

    return event;
  },
});
